<template>
  <div
    id="main-wrapper"
    v-if="st_subject"
    class="col-12"
    style="min-height: 97vh; padding-top: 15vh; margin-botton: 200px"
  >
    <div class="col-lg-12 col-md-12 text-center" style="margin-top: 50px">
      <div class="breadcrumbs-wrap">
        <h1 class="breadcrumb-title">Applying for {{ st_subject.en }}</h1>
      </div>
    </div>

    <div
      class="col-12 text-center"
      style="margin-top: 100px"
      v-if="st_subject.education == 's'"
    >
      <div>
        <h4>Select the grades you teach:</h4>
        <b-form-group style="font-size: 24px; margin-bottom: 50px">
          <b-form-checkbox-group id="checkbox-group-2" v-model="d_selected">
            <b-form-checkbox
              value="G1"
              :disabled="d_isAllSelected"
              :checked="d_selected"
              @change="normalSelect"
              >Grade 1-5</b-form-checkbox
            >
            <b-form-checkbox
              value="G2"
              :disabled="d_isAllSelected"
              :checked="d_selected"
              @change="normalSelect"
              >Grade 6-10</b-form-checkbox
            >
            <b-form-checkbox
              value="G3"
              :disabled="d_isAllSelected"
              :checked="d_selected"
              @change="normalSelect"
              >Grade 11-12</b-form-checkbox
            >
            <b-form-checkbox value="All" @change="selectAll"
              >All</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <div class="text-center">
        <h4>Select the curriculums you teach:</h4>
        <multiselect
          selectLabel="Select"
          deselectLabel="Remove"
          :class="['center', co_multiselectClass]"
          v-model="d_selectedCurriculums"
          :options="d_options"
          :multiple="true"
          placeholder="Pick curriculums"
          :close-on-select="false"
          label="en"
          track-by="key"
        >
        </multiselect>
      </div>
    </div>
    <div
      class="col-12 text-center"
      :style="
        st_subject.education == 's'
          ? 'margin-top: 50px; margin-bottom: 20vh'
          : 'margin-top: 200px; '
      "
    >
      <b-button @click="m_applyForSubject" type="button" class="btn btn-light"
        >Apply
        <b-icon-cursor-fill style="margin-left: 10px"></b-icon-cursor-fill
      ></b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  fb_tutorSubjectsCollection,
  fb_dataCollection,
  fb_FieldValue,
  fb_db,
} from "@/lib-core/lib-firebase";
import c_Multiselect from "vue-multiselect";
import { ios, android } from "platform-detect";

const l_allValues = ["G1", "G2", "G3", "All"];
export default {
  name: "cmp-apply",
  components: { multiselect: c_Multiselect },
  data() {
    return {
      d_isAllSelected: false,
      d_selected: [],
      d_selectedCurriculums: null,
      d_options: [],
    };
  },
  computed: {
    ...mapState({
      st_subject: (state) => state.md_fire.st_subject,
      co_multiselectClass() {
        return ios || android ? "multiselect-mobile" : "multiselect-web";
      },
    }),
    ...mapGetters("md_auth", [
      "gt_authUser",
      "gt_isAuthenticated",
      "gt_isVerified",
    ]),
    ...mapGetters("md_fire", ["gt_curriculums", "gt_curriculumSelected", "gt_user"]),
  },
  mounted() {
    setTimeout(() => {
      this.fillOptionsWithCurriculums();
      this.setSelectedCurriculum();
      if (this.st_subject == null) {
        this.$router.push({ name: "subjects" });
        return;
      }
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
  methods: {
    setSelectedCurriculum() {
      if (this.gt_curriculumSelected)
        this.d_selectedCurriculums = [this.gt_curriculumSelected];
    },
    async fillOptionsWithCurriculums() {
      this.d_options = [];

      for (var l_property in this.gt_curriculums) {
        let l_element = { key: l_property, ...this.gt_curriculums[l_property] };
        this.d_options.push(l_element);
      }
    },

    selectAll() {
      if (this.d_selected.indexOf("All") == -1) {
        this.d_selected = [];
        this.d_isAllSelected = false;
      } else {
        this.d_isAllSelected = true;
        this.d_selected = l_allValues;
      }
    },
    normalSelect() {
      if (this.d_selected.length == 3 && this.d_selected.indexOf("All") == -1) {
        this.d_isAllSelected = true;
        this.d_selected = l_allValues;
      }
    },
    async m_applyForSubject() {
      if (!this.m_checkEmailVerified()) return;

      let l_element = [];
      if (this.st_subject.education == "s") {
        // Curriculum not d_selected
        if (
          this.d_selectedCurriculums == null ||
          this.d_selectedCurriculums.length == 0
        ) {
          this.m_showvalidationpopup("Warning", "Please select a curriculum.");
          return;
        }
        // Grades not d_selected
        if (this.d_selected.length == 0) {
          this.m_showvalidationpopup("Warning", "Please select a grade.");
          return;
        }
        // Grade 1-5 and 1-12 are d_selected - PS: cannot select a discontinued grades
        if (
          this.d_selected.indexOf("G1") != -1 &&
          this.d_selected.indexOf("G3") != -1 &&
          this.d_selected.length == 2
        ) {
          this.m_showvalidationpopup(
            "Warning",
            "Cannot select gradde '1-5' and '11-12' as they are not continuous."
          );
          return;
        }

        this.d_selectedCurriculums.forEach((p_element) => {
          l_element.push(p_element.key);
        });
      }

      let l_payload = {
        status: "R",
        date: fb_FieldValue.serverTimestamp(),
        tutor: this.gt_authUser.email,
        tutorName: this.gt_user.account.displayName != null ? this.gt_user.account.displayName : "",
        subject: this.st_subject.key,
        en: this.st_subject.en,
        applicationId: `${this.gt_authUser.email}_${this.st_subject.key}`,
      };
      if (this.st_subject.education == "s") {
        l_payload.grade = this.m_getGrade();
        l_payload.curriculums = l_element.join(",");
      }
      await fb_db
        .collection("applications")
        .doc(l_payload.applicationId)
        .set(l_payload);

      await fb_tutorSubjectsCollection
        .doc(this.gt_authUser.email)
        .collection("subjects")
        .doc(this.st_subject.key)
        .set(l_payload);

      this.m_showvalidationpopup(
        "Completed",
        "The Administration will review your application",
        this.m_goTutorsubjects
      );
    },
    m_goTutorsubjects() {
      this.$router.push({ name: "tutoringsubjects" });
    },
    m_getGrade() {
      let l_grade = "";
      if (this.d_selected.indexOf("All") != -1) {
        l_grade = "1-12";
      } else {
        l_grade = this.m_lowestGrade() + "-" + this.m_highestGrade();
      }
      return l_grade;
    },
    m_lowestGrade() {
      let l_sortedSelected = this.d_selected.sort();
      let l_lowGrade = "";
      if (l_sortedSelected[0] == "G1") {
        l_lowGrade = "1";
      } else if (l_sortedSelected[0] == "G2") {
        l_lowGrade = "6";
      } else {
        l_lowGrade = "11";
      }
      return l_lowGrade;
    },
    m_highestGrade() {
      let l_sortedSelected = this.d_selected.sort();
      let l_highgrade = "";
      if (l_sortedSelected[l_sortedSelected.length - 1] == "G1") {
        l_highgrade = "5";
      } else if (l_sortedSelected[l_sortedSelected.length - 1] == "G2") {
        l_highgrade = "10";
      } else {
        l_highgrade = "12";
      }
      return l_highgrade;
    },
    m_showvalidationpopup(p_title, p_message, p_callback) {
      let l_payload = {
        title: p_title,
        message: p_message,
        button: {
          yes: "Ok",
        },
      };
      if (p_callback) l_payload.callback = p_callback;
      this.$confirm(l_payload);
    },
    m_checkEmailVerified() {
      if (this.gt_isAuthenticated && !this.gt_isVerified) {
        let l_payload = {
          title: "Email not verified",
          message: "Please verify your email before applying for a subject",
          button: {
            yes: "Verify",
            no: "Later",
          },
          callback: (confirm) => {
            if (confirm) {
              this.$router.push({ name: "verify" });
            }
          },
        };
        this.$confirm(l_payload);
        return false;
      }
      return true;
    },
  },
};
</script>
<style >
.multiselect-mobile {
  width: 100%;
  margin: auto;
}
.multiselect-web {
  width: 370px;
  margin: auto;
}
.multiselect__option {
  background: #82bf6f !important;
}
.multiselect__tag {
  background: #82bf6f !important;
}
.multiselect__option--highlight {
  background: #82bf6f !important;
}
.multiselect__option--selected {
  background: #d2555b !important;
}
</style>